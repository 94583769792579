<template>
		<div>
			<div 
				class="blog_cont"
				v-if="blog!=null&&$root.investor.loggedIn==true"
			>
				<div class=full>
					<div 
						v-if="blog.job=='investor'"
					>
						<router-link
							to="/investor/"
						>
							All Investor Updates 
						</router-link>
						|
						<router-link
							:to="'/investor/'+blog.blog_id"
						>
							This Update
						</router-link>
					</div>
					<div 
						v-if="blog.job=='news'"
					>
						<router-link
							to="/news/"
						>
							All News 
						</router-link>
						|
						<router-link
							:to="'/article/'+blog.blog_id"
						>
							This Press Release 
						</router-link>
					</div>
					<div 
						v-if="blog.job=='blog'"
					>
						<router-link
							to="/blogs/"
						>
							All Blogs
						</router-link>
						|
						<router-link
							:to="'/blog/'+blog.blog_id"
						>
							This Blog
						</router-link>
					</div>
					<br>
					Posted on {{ blog.readableTimestamp }}
				</div>
				<div class="full">
					Written By 
					<router-link
						:to="'/blogger/'+blog.creator_id"
					>
						{{ blog.first_name }} {{ blog.last_name }} 
					</router-link>
					<br>
					<br>

					<v-text-field
						label="Blog Title"
						v-model=blog.title
					>
					</v-text-field>
					<v-text-field
						label="Blurb"
						v-model=blog.blurb
					>
					</v-text-field>
					<v-text-field
						label="Header Image URL"
						v-model=blog.image
					>
					</v-text-field>
					<v-select
						v-model=blog.job
						:items="jobs"
						item-text="option"
						item-value="value"	
					>
					</v-select>
					<v-checkbox
						v-model=blog.published
						label="Published"
					>
					</v-checkbox>
					<v-btn
						:loading="saving"
						outlined
						v-on:click='save($event)'
					>
						Save
					</v-btn>
					<br>
					<br>
					<div v-if="dirty">
						<i>Changed</i>
					</div>
					<v-textarea
						height="500px"
						outlined
						name="body"
						label="Body"
						v-model="blog.body"
						v-on:change="changed()"
					>
					</v-textarea>
				</div>	
			</div>
                        <div
                                class="blog_cont center"
                                v-if="$root.investor.loggedIn==false"
                        >
                                <router-link
                                        to="/"
                                >
                                        Home
                                </router-link>
                                <h1> You Have To Be Logged In To View This Page </h1>
                        </div>
			<div 
				class="blog_cont center"
				v-else
			>
				<router-link
					to="/blogs/"
				>
					All Blogs
				</router-link>
				<h1> This Blog Does Not Exist </h1>
			</div>
		</div>
</template>

<script>
module.exports = {
	data() {
		return {
			dirty: false,
			jobs: [ 
				{ option: "News Item", value: "news"},
				{ option: "Blog", value: "blog" },
				{ option: "Investor Update", value: "investor" }
			],	
			blog: {
				blog_id: -1,
				title: "",
				image: "",
				published: false,
				body: ""
			},
			saving: false,
			titleFontStyle: {},
			titleFontStyleMobile: {},
			textFontStyle: {},
			textFontStyleMobile: {}, };
	},
	methods: {
		changed() {
			this.dirty = true;
		},
		save(e) {

			if(this.blog==null||this.blog.blog_id==-1) {
				return;
			}
			
			var d = {}
			d.blog_id = this.blog.blog_id	
			d.title = this.blog.title
			d.image = this.blog.image
			d.published = this.blog.published
			d.body = this.utf8_to_b64(this.blog.body)
			d.blurb = this.blog.blurb
			d.job = this.blog.job
	
			this.saving = true;	
			var ep = "/svc/blog/"+this.blog.blog_id+"/"
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			console.log("POSTing:\n" + JSON.stringify(d))

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			this.$http.post(ep, d, { headers }).then(response => {
				this.saving = false;	
				if(response.data.code==200) {
					alert("saved");
					this.dirty = false;
				} else {
					alert("Unable to save:  " + response.data.description);
				}
			})
			
		},
		getReadableDate(ts) {
			console.log(ts);
			var t = new Date(ts*1000);
			//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			var times = t.toLocaleDateString("en-US", options);
			console.log(times);
			return(times);
		},

		utf8_to_b64( str ) {
			return window.btoa(unescape(encodeURIComponent( str )));
		},

		b64_to_utf8( str ) {
			return decodeURIComponent(escape(window.atob( str )));
		}
	},
	mounted() {
		console.log("Logged in: " + JSON.stringify(this.$root.$data));
		this.$http.get("/svc"+this.$route.path.replace("edit","blog")).then(response => {
			this.blog = response.data.blog?response.data.blog[0]:null;
			this.titleFontStyle = response.data.global.title_font_style;
			this.titleFontStyleMobile = response.data.global.title_font_style_mobile;
			this.textFontStyle = response.data.global.text_font_style;
			this.textFontStyleMobile = response.data.global.text_font_style_mobile;

			/* Method for presereving unicode characters
			*/
				
			this.blog["readableTimestamp"] = this.getReadableDate(this.blog.ts);
			console.log("TS: " + this.blog["readableTimestamp"]);
			this.blog.body=this.b64_to_utf8(this.blog.body);
		})
	}

};
</script>

<style scoped>
.blog_cont {
	max-width: 1200px;
	height: 100%;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}

.full {
}

.left_cont {
	display: inline;
	width: 20%;
	float: left;
}
.right_cont {
	display: inline;
	width: 20%;
	float: left;
}
.main_cont {
	display: inline;
	width: 60%;
	float: left;
}
.primary_img {
	max-height: 200px;
	max-width: 700px;
	object-fit: cover;
}

.mobile {
	margin: 0px 10px 0px 10px;
}

</style>


<style>
blockquote {
	padding: 0 1em;
	border-left: 3px solid rgba(0,0,0,.05);
	margin: 0 0 1.41575em 3em;
	font-style: italic;
}
hr {
	width:80%;
	text-align:left;
	margin-left:0;
	color: rgba(0,0,0,0.05);
}
ol, ul {
	margin: 0 0 1.41575em 3em;
	padding: 0;
}
</style>
